import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import ReactPlayer from 'react-player'
import Video1 from './assets/vid/video1.mp4';
import styled from "styled-components";
import img_parallax from './assets/img/Screenshot_15.jfif';
import img_parallax4 from './assets/img/Screenshot_19.jfif'
import img_parallax3 from './assets/img/Screenshot_20.jfif'
import img_parallax2 from './assets/img/Screenshot_23.jfif'
import ReactMegaMenu from "react-mega-menu"
import { StickyContainer, Sticky } from 'react-sticky';
import { Helmet } from "react-helmet";
import { Container, Row, Col, Button } from 'react-bootstrap';
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons'
//import App from './App';
//import * as serviceWorker from './serviceWorker';

var scrollToElement = require('scroll-to-element');


library.add(faEnvelope, faPhone)

const StyledHeaderContact = styled(Col)`       
    @media only screen and (max-width: 767px) {
        text-align: center;
    }
`;

const StyledHeaderMenu = styled(Row)`       
    @media only screen and (max-width: 767px) {
        display: none;
    }
`;

const StyledNavbar = styled(Navbar)`       
    background-color: #fff;
    font-family: 'Roboto', sans-serif;
    .col {
        padding-right: 0px;
        padding-left: 0px;
    }
    .header-title {     
        text-align: center;
        font-size: 2.8rem;
        line-height: 4rem;
        letter-spacing: 6px;
        font-weight: bold;
    }

    .header-subtitle {
        text-align: center;
        @media only screen and (min-width: 767px) {
            font-size: 1.4rem;
        }
    }

    .header-contact {
        font-size: 1.2rem;
    }

    svg {
        margin-right: 10px;
    }

    ul {
         display: flex;
         flex-wrap: wrap;
         justify-content: space-between;
         list-style: none;    
         width: 50%;
         margin: 0px;
         padding: 0px 0px 10px 0px;
     }
     ul li a {
         font-size: 1.2rem;
         color: #000;
         position: relative;
         -webkit-text-decoration: none;
         text-decoration: none;        
         font-family: 'Roboto', sans-serif;
     }    
 
     ul li a:hover::after {
         width: 100%;
         background-color: #000;
     }
 
     ul li a::after {
         position: absolute;
         bottom: -3px;
         left: 0;
         content: '';
         width: 0;
         height: 2px;
         -webkit-transition: all 0.2s linear;
         transition: all 0.2s linear;
     }
`;

function Navbar({ className, style }) {
    return (
        /*<div className={className} style={style}>
            <span style={{paddingTop:20, paddingBottom:20}}>TRAVELTIME LUGGAGE</span>
            <span>Indonesia luggage manufacturer</span>
            <span></span>
            <ul>
                <li><a href="#">Home</a></li>
                <li><a href="#">Our Factory</a></li>
                <li><a href="#">About Us</a></li>
                <li><a href="#">Portfolio</a></li>
                <li><a href="#">Contact Us</a></li>
            </ul>
        </div>*/
        <div className={className}>
            <Row>
                <Col className="d-flex justify-content-center">
                    <span className="header-title" style={{ padding: '30px 0px 0px 0px' }}>TRAVELTIME LUGGAGE</span>
                </Col>
            </Row>
            <Row>
                <Col className="d-flex justify-content-center">
                    <span className="header-subtitle" style={{ paddingBottom: '10px' }}>INDONESIA LUGGAGE MANUFACTURER</span>
                </Col>
            </Row>
            <Row className="d-flex justify-content-center" style={{ paddingBottom: "15px" }}>
                <StyledHeaderContact className="header-contact" md="auto" sm={12}>
                    <FontAwesomeIcon icon="phone" />+62 21 6908828
                </StyledHeaderContact>
                <StyledHeaderContact className="header-contact" md="auto" sm={12}>
                    <FontAwesomeIcon icon="envelope" /><a href="mailto:sangratu@traveltime-luggage.com">sangratu@traveltime-luggage.com</a>
                </StyledHeaderContact>
            </Row>
            <StyledHeaderMenu>
                <Col className="d-flex justify-content-center">
                    <ul>
                        {/*<li><a href="#" onClick={ () => scrollToElement("#home") } >Home</a></li>*/}
                        <li><a href="#" onClick={() => scrollToElement("#aboutus")}>About Us</a></li>
                        <li><a href="#" onClick={() => scrollToElement("#ourstaff")}>Our Staff and Workers</a></li>
                        <li><a href="#" onClick={() => scrollToElement("#address-contact")}>Our Address</a></li>
                        <li><a href="#" onClick={() => scrollToElement("#address-contact")}>Contact Us</a></li>
                    </ul>
                </Col>
            </StyledHeaderMenu>
        </div>
    );
}
const CSSPaddingTopBottom20px = `
    padding-top: 20px;
    padding-bottom: 20px;
`;

const CSSPaddingTopBottom40px = `
    padding-top: 40px;
    padding-bottom: 40px;
`;

const CSSPaddingTopBottom50px = `
    padding-top: 50px;
    padding-bottom: 50px;
`;

const StyledContainerWhiteBg = styled(Container)`  
    background-color: #fff;        
`;

const StyledHeaderWhiteBg = styled.div`    
background-color: #fff;    

.header-subtitle {
    font-size: 1.6rem;    
}
`;

const StyledDivWhiteBg = styled.div`    
    ${CSSPaddingTopBottom50px}
    background-color: #fff;    
    font-size: 1.2rem;
    span {
        font-family: 'Roboto', sans-serif;
        font-size: 2.6rem;
        line-height: 4rem;
        letter-spacing: 6px;
        
    }
`;

class Header extends React.Component {
    static defaultProps = {
        className: ""
    };
    render() {
        const { style, renderCount, className } = this.props;
        return (
            <div className={"header " + className} style={style}>
                <StyledNavbar />
            </div>
        );
    }
}

const StyledPalx = styled(Palx)`
        background-image: url(${props => props.image});
        height: 400px;
        position: relative;        
        background-attachment: fixed;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    `;

function Palx({ className, style }) {
    return (
        <div className={className} style={style} />
    );
}

function Home() {
    return (
        <>
            <Helmet>
                <title>Traveltime Luggage - PT. Sangra Ratu Gemilang - Indonesia</title>
                <meta name="description" content="PT. Sangra Ratu Gemilang. Luggage manufacturer since 1988. Luggage Store. Located in Indonesia." />
                <meta name="robots" content="all" />
            </Helmet>
            <StickyContainer className="stickyheader-display" id="home">
                <Sticky>
                    {({ style }) => (
                        <Header style={style} />
                    )}
                </Sticky>
                <div style={{ height: "calc(100vh - 200px)" }}></div>
            </StickyContainer>
            <Header className="nonstickyheader-display" />
            <ReactPlayer id="bgcover" url={Video1} playing loop volume={0} muted controls={false} />
            
                <StyledDivWhiteBg id="aboutus">
                <Container>
                    <span>About Us</span>
                    <p>Established since 1988, <b>Traveltime Luggage (PT. Sangra Ratu Gemilang)</b> is specialized in producing large quantity of luggage, carry cart, school bag, laptop backpack, tote bag, shopping bag and waist bag. Other custom model bags are welcome. </p>
                    <br />
                    <p>Our approximate monthly capacity for Softcase Luggage/Trolley Case is around 15,000 pcs/month and for Hardcase Luggage/Trolley Case is around 6,000 pcs/month.</p>
                    <br />
                    We have exported our products to the following countries:
                    <ul style={{ margin: "10px 30px" }}>
                        <li>
                            America: USA, Canada, Trinidad, Mexico
                            </li>
                        <li>
                            Asia: Singapore, Malaysia, Japan, Taiwan, Australia, New Zealand, Philippine, Papua New Guinea
                            </li>
                        <li>
                            Europe: Netherlands, France, Germany, Italy, England, Austria, Norway, Denmark, Spain, Switzerland
                            </li>
                        <li>
                            Middle East: Saudi Arabia, UAE, Jordan
                            </li>
                        <li>
                            Africa: Cameroon, Ghana, Nigeria, Mauritania, Zaire
                            </li>
                    </ul>
                    </Container>
                </StyledDivWhiteBg>
            
            <StyledPalx image={img_parallax2} />
            
                <StyledDivWhiteBg id="ourstaff">
                <Container>
                    <span>Our Staff and Workers</span>
                    <p>With our <b>200 workers</b> and <b>20 staff</b>, we are committed to create high quality products and delivered it as scheduled.<br /> Products quality is constantly checked and monitored by our experienced Quality Control Division staff.</p>
                    </Container>
                </StyledDivWhiteBg>
            
            <StyledPalx image={img_parallax4} />
            
            <StyledDivWhiteBg id="address-contact">
            <StyledContainerWhiteBg>
                    <Row>
                        <Col sm={12} md={6}>
                            <h3><b>Our Address</b></h3>
                            <h5><b>PT. Sangra Ratu Gemilang</b></h5>
                            Jl. Karang Bolong Raya No. 8<br />
                            Ancol 14430<br />
                            Jakarta Utara<br />
                            <br />
                        </Col>
                        <Col sm={12} md={6}>
                            <h3><b>Contact Us</b></h3>
                            Email: <a href="mailto:sangratu@traveltime-luggage.com">sangratu@traveltime-luggage.com</a><br />
                            Telp: +62 21 6908828
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col>
                            <h3><b>Our Location</b></h3>
                            <div style={{ textDecoration: "none", overflow: "hidden", maxWidth: "100%", width: "100%", height: 400 }}>
                                <div id="canvasfor-googlemap" style={{ height: "100%", width: "100%", maxWidth: "100%" }}>
                                    <iframe style={{ height: "100%", width: "100%", border: 0 }} frameBorder="0" src="https://www.google.com/maps/embed/v1/place?q=traveltime+luggage&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8" />
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row style={{ padding: "50px 0px 0px 0px" }}>
                        <Col className="small">
                            © 2019 PT. Sangra Ratu Gemilang
                        </Col>
                    </Row>
                    </StyledContainerWhiteBg>                        
            </StyledDivWhiteBg>
        </>
    );
}

ReactDOM.render(<Home />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
//serviceWorker.unregister();

/*
            <StyledPalx image={img_parallax} />
            <StyledDivWhiteBg>
                <Container>
                    <span>Our Service</span>
                    <p></p>
                </Container>

            </StyledDivWhiteBg>

                        <StyledDivWhiteBg className="whitebg" style={{ height: 300 }}>


            </StyledDivWhiteBg>
            <StyledPalx image={img_parallax4} />

            <StyledDivWhiteBg className="whitebg" style={{ height: 300 }}>
                <Container>
                </Container>
            </StyledDivWhiteBg>
            <StyledPalx image={img_parallax3} />

*/